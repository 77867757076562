import { login, logout, getInfo } from '@/api/pub/login'
import { wxLogin } from '@/api/wxwork'
import { getToken, setToken, removeToken } from '@/utils/auth'
import Cookies from 'js-cookie'

const user = {
  state: {
    token: getToken(),
    userName: '',
    avatar: '',
    roles: [],
    permissions: [],
    userId: null,
    nickName: '',
    sex: ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, userName) => {
      state.userName = userName
    },
    SET_NICKNAME: (state, nickName) => {
      state.nickName = nickName
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_SEX: (state, sex) => {
      state.sex = sex
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        
        login(username, password, code, uuid).then(res => {
          setToken(res.token)
          commit('SET_TOKEN', res.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    WxLogin({ commit }, code) {
      return new Promise((resolve, reject) => {
        wxLogin(code).then(res => {
          console.log(res)
          setToken(res.data.token)
          commit('SET_TOKEN', res.token)
          resolve()
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          const user = res.user
          const avatar = (user.avatar == "" || user.avatar == null) ? (user.sex == 0 ? require('@/assets/images/avatar/man' + user.userId % 4 + '.png') : require('@/assets/images/avatar/woman' + user.userId % 4 + '.png')) : process.env.VUE_APP_BASE_API + user.avatar;
          if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.roles)
            commit('SET_PERMISSIONS', res.permissions)
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT'])
          }
          commit('SET_NAME', user.userName)
          commit('SET_NICKNAME', user.nickName)
          commit('SET_USERID', user.userId)
          commit('SET_AVATAR', avatar)
          commit('SET_SEX', user.sex)

          // const { phonenumber, email} = res.user
          // const test = (str) => {
          //   return (!str || /^\s*$/.test(str));
          // }
          // if(test(phonenumber || test))

          // if(!phonenumber || !email) {
          //   MessageBox('您的个人信息不完整，请填写邮箱信息')
          // }

          resolve(res)


        }).catch(error => {
          reject(error)
        })
      })
    },
    
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        Cookies.remove("portal-password");
        Cookies.remove("portal-rememberMe");
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          commit('SET_AVATAR', [])
          removeToken()
          sessionStorage.removeItem('channelId')
          sessionStorage.removeItem('calculator-history-session')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
